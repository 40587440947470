.header {
  height: rem(66px);
  background-color: var(--mantine-color-body);
  border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  padding-left: var(--mantine-spacing-xl);
  padding-right: var(--mantine-spacing-xl);
}

.inner {
  height: rem(56px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  display: block;
  line-height: 1;
  padding: rem(8px) rem(12px);
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;

  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  }
}

.linkLabel {
  margin-right: rem(5px);
}

.logoContainer {
  min-width: 9.8125em !important;
  border: none !important;
  cursor: 'pointer';

  @media (max-width: $mantine-breakpoint-xs) {
    min-width: 9.8125em !important;

    .header {
      padding-left: var(--mantine-spacing-sm);
      padding-right: var(--mantine-spacing-sm);
    }
  }

  @media (max-width: $mantine-breakpoint-md) {
    min-width: 9.8125em !important;

    .header {
      padding-left: var(--mantine-spacing-sm);
      padding-right: var(--mantine-spacing-sm);
    }
  }

  @media (max-width: $mantine-breakpoint-lg) {
    min-width: 9.8125em !important;
  }

  @media (max-width: $mantine-breakpoint-xl) {
    min-width: 9.8125em !important;
  }
}