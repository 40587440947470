.Navbar_header__vGGic {
  height: calc(4.125rem * var(--mantine-scale));
  background-color: var(--mantine-color-body);
  border-bottom: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-3);
}
  [data-mantine-color-scheme='dark'] .Navbar_header__vGGic {
  border-bottom: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-dark-4);
}
  .Navbar_header__vGGic {
  padding-left: var(--mantine-spacing-xl);
  padding-right: var(--mantine-spacing-xl);
}

.Navbar_inner__r3nfj {
  height: calc(3.5rem * var(--mantine-scale));
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Navbar_link__Wf_Pl {
  display: block;
  line-height: 1;
  padding: calc(0.5rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale));
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: var(--mantine-color-gray-7);
}

[data-mantine-color-scheme='dark'] .Navbar_link__Wf_Pl {
  color: var(--mantine-color-dark-0);
}

.Navbar_link__Wf_Pl {
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;
}

@media (hover: hover) {
  .Navbar_link__Wf_Pl:hover {
    background-color: var(--mantine-color-gray-0);
  }
    [data-mantine-color-scheme='dark'] .Navbar_link__Wf_Pl:hover {
    background-color: var(--mantine-color-dark-6);
  }
}

@media (hover: none) {
  .Navbar_link__Wf_Pl:active {
    background-color: var(--mantine-color-gray-0);
  }
    [data-mantine-color-scheme='dark'] .Navbar_link__Wf_Pl:active {
    background-color: var(--mantine-color-dark-6);
  }
}

.Navbar_linkLabel__ySPnt {
  margin-right: calc(0.3125rem * var(--mantine-scale));
}

.Navbar_logoContainer__t_wYq {
  min-width: 9.8125em !important;
  border: none !important;
  cursor: 'pointer';
}

@media (max-width: 36em) {

.Navbar_logoContainer__t_wYq {
    min-width: 9.8125em !important
}

    .Navbar_logoContainer__t_wYq .Navbar_header__vGGic {
      padding-left: var(--mantine-spacing-sm);
      padding-right: var(--mantine-spacing-sm);
    }
  }

@media (max-width: 62em) {

.Navbar_logoContainer__t_wYq {
    min-width: 9.8125em !important
}

    .Navbar_logoContainer__t_wYq .Navbar_header__vGGic {
      padding-left: var(--mantine-spacing-sm);
      padding-right: var(--mantine-spacing-sm);
    }
  }

@media (max-width: 75em) {

.Navbar_logoContainer__t_wYq {
    min-width: 9.8125em !important
}
  }

@media (max-width: 88em) {

.Navbar_logoContainer__t_wYq {
    min-width: 9.8125em !important
}
  }
